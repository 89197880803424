$(document).ready(() => {
  $('li.tab > a').on('click', (e) => {
    let target = $(e.currentTarget)
    let parent = target.parent()

    if (parent.hasClass('on')) {
      e.preventDefault()
      return
    }

    $('.tab.on').removeClass('on')
    parent.addClass('on')

    // 실제 탭 내용
    $('.tab-contents.on').removeClass('on')
    $(target.attr('href')).addClass('on')

    e.preventDefault()
  })
})
