
$(document).ready(() => {
  initEvent()

  function initEvent () {
    $('#inputCertified').on('keydown', (e) => {
      if (e.keyCode === 13) {
        $('#btnCertified').trigger('click')
      }
    })

    $('#inputKapaParts').on('keydown', (e) => {
      if (e.keyCode === 13) {
        $('#btnKapaParts').trigger('click')
      }
    })

    $('#btnCertified').on('click', (e) => {
      console.log('[event] search certified')
    })

    $('#btnKapaParts').on('click', (e) => {
      console.log('[event] search kaka parts')
    })

    $('#btnTop').on('click', () => {
      window.scrollTo(0, 0)
    })
  }
})
