
$(document).ready(() => {
  initEvent()
  initAccordion()
  quickScroll()
  initInput()

  function initEvent () {
    $('#btnTop').on('click', () => {
      window.scrollTo(0, 0)
    })
  }

  function initAccordion () {
    $('.btn-accordion').on('click', (e) => {
      let target = $(e.currentTarget)
      target.toggleClass('open')
      $('#' + target.attr('data-target')).toggleClass('close')
      $('#' + target.attr('data-target')).parent().toggleClass('close')
    })
  }

  /**
   * 스크롤시 active 내용선택
   */
  function quickScroll () {
    let elems = $('[data-quick-menu] [data-quick-scroll]')

    $(window).on('scroll', () => {
      let oldElem = $('[data-quick-menu] .on[data-quick-scroll]')

      for (let i = 0; i < elems.length; i++) {
        let elem = elems.eq(i)
        let id = elem.attr('data-quick-scroll')
        let target = $('#' + id)
        let curOffset = window.innerHeight / 2 + window.scrollY
        let targetOffset = target.offset().top + parseInt(target.css('padding-top'))

        if (curOffset > targetOffset && window.scrollY < targetOffset) {
          if (oldElem !== elem) $('.on[data-quick-scroll]').removeClass('on')
          elem.addClass('on')
          target.addClass('on')
          return
        }
      }
    })
  }

  function initInput () {
    // $(document).on('keyup', '.kapa-input-wrap > input', (e) => {
    //   let target = $(e.currentTarget)
    //   if (target.val() === '') {
    //     target.next().show()
    //   } else {
    //     target.next().hide()
    //   }
    // })
    $(document).on('keyup', '.kapa-input-wrap > textarea', (e) => {
      let target = $(e.currentTarget)
      if (target.val() === '') {
        target.parent().find('button').prop('disabled', true)
        target.parent().find('button').addClass('btn-border btn-grey disabled')
        target.parent().find('button.btn-comment').removeClass('btn-basic')
        target.parent().find('button.btn-cancel').removeClass('btn-basic btn-red')
        // target.next().show()
      } else {
        target.parent().find('button').prop('disabled', false)
        target.parent().find('button').removeClass('btn-border btn-grey disabled')
        target.parent().find('button.btn-comment').addClass('btn-basic')
        target.parent().find('button.btn-cancel').addClass('btn-basic btn-red')
        // target.next().hide()
      }
    })

    // 댓글 작성 취소 버튼 클릭 이벤트
    $('.btn-cancel').on("click", (e) => {
      let target = $(e.currentTarget);
      target.parents("div.kapa-input-wrap").find('textarea').val("");
      target.parent().find('button').prop('disabled', true);
      target.parent().find('button').addClass('btn-border btn-grey disabled');
      target.parent().find('button.btn-comment').removeClass('btn-basic')
      target.parent().find('button.btn-cancel').removeClass('btn-basic btn-red')
    });
  }

  $.fn.hasOverflown = function () {
    var res
    var cont = $('<div>' + this.text() + '</div>').css('display', 'table')
    .css('z-index', '-1').css('position', 'absolute')
    .css('display', this.css('display'))
    .css('font-family', this.css('font-family'))
    .css('font-size', this.css('font-size'))
    .css('letter-spacing', this.css('letter-spacing'))
    .css('font-weight', this.css('font-weight')).appendTo('body')
    res = (cont.width() > this.width())
    cont.remove()
    return res
  }
})
