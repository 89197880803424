$(document).ready(() => {
  let isHiddenPage = false;
  let elem = $('.import-hidden-row-table');
  if (elem && elem.length > 0) {
    isHiddenPage = true;
  }

  if (isHiddenPage) {
    $(document).on('click', 'table td', e => {
      if ($(e.target).hasClass('fullname-float')) {
        $('.fullname-float').remove();
        return;
      }
      let target = $(e.currentTarget);

      $('.active').removeClass('active');
      target.parent().toggleClass('active');
    });

    $(document).on('click', '.fullname', e => {
      let target = $(e.currentTarget);
      if (!target.hasOverflown()) return;

      $('.fullname-float').remove();
      target.after('<div class="fullname-float">' + target.html() + '</div>');

      e.stopPropagation();
      e.preventDefault();
    });

    $(document).on('mouseover', '.fullname', e => {
      if (window.innerWidth <= 768) return;
      let target = $(e.currentTarget);
      if (!target.hasOverflown()) return;

      $('.fullname-float').remove();
      target.after('<div class="fullname-float">' + target.html() + '</div>');
    });

    $(document).on('mouseleave', '.fullname-float', e => {
      if (window.innerWidth <= 768) return;
      $('.fullname-float').remove();
    });

    $(window).on('scroll', () => {
      $('.fullname-float').remove();
    });
  }
});
