window.initStickySubHeader = function () {
  $(document).ready(() => {
    let sub = $('.sub-nav-wrap.pc-sub-menu').clone().addClass('fix-sub-header')

    $('.kapa-wrap').append(sub)

    if (window.scrollY >= $('header .sub-nav-wrap.pc-sub-menu').get(0).offsetTop) {
      sub.show()
    } else {
      sub.hide()
    }

    $(window).on('scroll', () => {
      if (window.scrollY >= $('header .sub-nav-wrap.pc-sub-menu').get(0).offsetTop) {
        $('.kapa-wrap .sub-nav-wrap.pc-sub-menu').show()
      } else {
        $('.kapa-wrap .sub-nav-wrap.pc-sub-menu').hide()
      }
    })
  })
}
