/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Importing Application.js packed from Webpacker');

// import '../test.js';
//import '../fonts/fonts';
import '../styles/index';

import '../scripts/cash.js';
import '../scripts/header.js';
import '../scripts/selectbox.js';
import '../scripts/common.js';
import '../scripts/index.js';
import '../scripts/banner.js';
import '../scripts/tab.js';
import '../scripts/certification_detail.js';

import '../scripts/fixed_scroll_sub.js';

//<link href="/javascript/styles/vendor/bootstrap/grid.css" rel="stylesheet">
//<link href="/javascript/styles/index.scss" rel="stylesheet">

require.context('../img', true);
