$(document).ready(() => {
  $.fn.initMobile = function () {
    // debugger
    let $this = $(this)
    let id = $this.attr('id')
    let $list = $this.parent().find('.select-options')
    var $mobileWrap = $('.select-mobile-wrap[data-select="' + id + '"]')
    var $listMobile = $mobileWrap.find('.select-options-mobile')
    var $styledSelect = $this.next('div.select-styled')

    $list.html('')
    $listMobile.html('')
    $mobileWrap.find('.select-header').remove()
    
    let numberOfOptions = $(this).children('option,optgroup').length

    for (let i = 0; i < numberOfOptions; i++) {
      let targetOption = $this.children('option,optgroup').eq(i)

      let classList = []
      
      if (targetOption.prop('disabled')) {
        classList.push('disabled')
      }

      if (targetOption.hasClass('optgroup')) {
        classList.push('optgroup')
      }

      if (!targetOption.hasClass('mobile-title')) {
        if (targetOption.hasClass('optgroup')) {
          $('<li rel="' + targetOption.val() + '" class="' + classList.join(' ') + '">' + targetOption.attr('label') + '</li>').appendTo($list)
        } else {
          $('<li rel="' + targetOption.val() + '" class="' + classList.join(' ') + '">' + targetOption.text() + '</li>').appendTo($list)
        }
      }

      if (i === 0 || targetOption.hasClass('mobile-title')) {
        $mobileWrap.prepend('<div class="select-header"><div class="title">' + targetOption.text() + '</div><button class="btn-close"></button></div>')
      } else if (targetOption.hasClass('optgroup')) {
        $('<li rel="' + targetOption.val() + '" class="' + classList.join(' ') + '">' + targetOption.attr('label') + '</li>').appendTo($listMobile)
      } else {
        $('<li rel="' + targetOption.val() + '" class="' + classList.join(' ') + '">' + targetOption.text() + '</li>').appendTo($listMobile)
      }
    }

    var $listItems = $list.children('li:not(.optgroup)')
    var $listItemsMobile = $listMobile.children('li:not(.optgroup)')

    $listItems.off('click')
    $listItems.on('click', function (e) {
      if ($(this).hasClass('disabled')) {
        e.preventDefault()
        e.stopPropagation()
        return
      }

      e.stopPropagation()
      $styledSelect.text($(this).text()).removeClass('active')
      $this.val($(this).attr('rel'))
      $this.trigger('change')
      $list.hide()
    })

    $listItemsMobile.on('click', function (e) {
      if ($(this).hasClass('disabled')) {
        e.preventDefault()
        e.stopPropagation()
        return
      }

      e.stopPropagation()
      $this.val($(this).attr('rel'))
      $this.trigger('change')
      $styledSelect.text($(this).text()).removeClass('active')
      $mobileWrap.removeClass('active')
      $list.hide()
      $('html').toggleClass('lock-nav')
    })
  }

  $('select').each(function () {
    let $this = $(this)
    let numberOfOptions = $(this).children('option,optgroup').length
    let id = $this.attr('id')

    $this.wrap('<div class="select ' + $this.attr('class') + '"></div>')
    $this.addClass('select-hidden')
    $('.kapa-wrap').append('<div class="select-mobile-wrap" data-select="' + id + '"><div class="select-styled-mobile"></div></div>')
    $this.after('<div class="select-styled"></div>')

    let parent = $this.parent()

    var $styledSelect = $this.next('div.select-styled')

    let selectedOption = $this.children('option[selected]')

    $styledSelect.text((selectedOption.length > 0 ? selectedOption : $this.children('option').eq(0)).text())

    var $mobileWrap = $('.select-mobile-wrap[data-select="' + id + '"]')

    var $list = $('<ul class="select-options" />').insertAfter($styledSelect)
    var $listMobile = $('<ul class="select-options-mobile" />').appendTo($mobileWrap)

    for (let i = 0; i < numberOfOptions; i++) {
      let targetOption = $this.children('option,optgroup').eq(i)

      let classList = []
      
      if (targetOption.prop('disabled')) {
        classList.push('disabled')
      }

      if (targetOption.hasClass('optgroup')) {
        classList.push('optgroup')
      }

      if (!targetOption.hasClass('mobile-title')) {
        if (targetOption.hasClass('optgroup')) {
          $('<li rel="' + targetOption.val() + '" class="' + classList.join(' ') + '">' + targetOption.attr('label') + '</li>').appendTo($list)
        } else {
          $('<li rel="' + targetOption.val() + '" class="' + classList.join(' ') + '">' + targetOption.text() + '</li>').appendTo($list)
        }
      }

      if (i === 0 || targetOption.hasClass('mobile-title')) {
        $mobileWrap.prepend('<div class="select-header"><div class="title">' + targetOption.text() + '</div><button class="btn-close"></button></div>')
      } else if (targetOption.hasClass('optgroup')) {
        $('<li rel="' + targetOption.val() + '" class="' + classList.join(' ') + '">' + targetOption.attr('label') + '</li>').appendTo($listMobile)
      } else {
        $('<li rel="' + targetOption.val() + '" class="' + classList.join(' ') + '">' + targetOption.text() + '</li>').appendTo($listMobile)
      }
    }

    var $listItems = $list.children('li:not(.optgroup)')
    var $listItemsMobile = $listMobile.children('li:not(.optgroup)')

    $styledSelect.on('click', function (e) {
      e.stopPropagation()

      $('div.select-styled.active').not(this).each(function () {
        $(this).removeClass('active').next('ul.select-options').hide()
      })

      $(this).toggleClass('active').next('ul.select-options').toggle()
      $mobileWrap.toggleClass('active')
      $('html').toggleClass('lock-nav')
    })

    $listItems.on('click', function (e) {
      if ($(this).hasClass('disabled')) {
        e.preventDefault()
        e.stopPropagation()
        return
      }

      e.stopPropagation()
      $styledSelect.text($(this).text()).removeClass('active')
      $this.val($(this).attr('rel'))
      $this.trigger('change')
      $list.hide()
    })

    $listItemsMobile.on('click', function (e) {
      if ($(this).hasClass('disabled')) {
        e.preventDefault()
        e.stopPropagation()
        return
      }

      e.stopPropagation()
      $this.val($(this).attr('rel'))
      $this.trigger('change')
      $styledSelect.text($(this).text()).removeClass('active')
      $mobileWrap.removeClass('active')
      $list.hide()
      $('html').toggleClass('lock-nav')
    })

    $(document).on('click', function () {
      $styledSelect.removeClass('active')
      $list.hide()
    })

    $(document).on('click', '.btn-close', function () {
      $(this).closest('.select-mobile-wrap').removeClass('active')
      $('html').removeClass('lock-nav')
      $list.hide()
    })
  })
})
