$(document).ready(() => {
  $('#btnMenuOpen').on('click', (e) => {
    $('.side-menu').show()
    $('.side-menu').addClass('navi-on')
    $('html').addClass('lock-nav')
  })

  $('#btnMenuClose').on('click', (e) => {
    $('.side-menu').removeClass('navi-on')
    $('html').removeClass('lock-nav')

    setTimeout(() => {
      $('.side-menu').hide()
    }, 200)
  })
})
