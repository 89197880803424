$(document).ready(() => {
  const SLIDE_DURATION = 4500;
  const FADE_DURATION = 500;
  const BANNER_LIST = [
    /*
    {
      image: {
        url:
          'https://s3.ap-northeast-2.amazonaws.com/bellachoi-public/banner-1.png',
      },
      image_tablet: {
        url:
          'https://s3.ap-northeast-2.amazonaws.com/bellachoi-public/banner-mobile-1.png',
      },
      image_mobile: {
        url:
          'https://s3.ap-northeast-2.amazonaws.com/bellachoi-public/banner-mobile-1.png',
      },
      image_retina: {
        url:
          'https://s3.ap-northeast-2.amazonaws.com/bellachoi-public/banner-mobile-1@2x.png',
      },
    },
    {
      image: {
        url:
          'https://s3.ap-northeast-2.amazonaws.com/bellachoi-public/banner-2.png',
      },
      image_tablet: {
        url:
          'https://s3.ap-northeast-2.amazonaws.com/bellachoi-public/banner-mobile-2.png',
      },
      image_mobile: {
        url:
          'https://s3.ap-northeast-2.amazonaws.com/bellachoi-public/banner-mobile-2.png',
      },
      image_retina: {
        url:
          'https://s3.ap-northeast-2.amazonaws.com/bellachoi-public/banner-mobile-2@2x.png',
      },
    },
    {
      'image': { 'url' : 'https://s3.ap-northeast-2.amazonaws.com/bellachoi-public/banner-3.png' },
      'image_tablet': { 'url' : 'https://s3.ap-northeast-2.amazonaws.com/bellachoi-public/banner-mobile-3.png' },
      'image_mobile': { 'url' : 'https://s3.ap-northeast-2.amazonaws.com/bellachoi-public/banner-mobile-3.png' },
      'image_retina': { 'url' : 'https://s3.ap-northeast-2.amazonaws.com/bellachoi-public/banner-mobile-3@2x.png' }
    }
    */
  ];

  let isSlideProc = false;
  let sliderInterval;

  /**
   * 초기 slider init.
   * 이미지 데이터와 함께 넘김.
   *
   * @param {Array} list
   */
  function initSlider(list) {
    let bannerList = getMainBannerList();
    $.merge(bannerList, list);
    setSlideList(bannerList);

    $('#slideTotalCount').text(bannerList.length);

    $('#btnSlideNext').on('click', nextSlide);
    $('#btnSlidePrev').on('click', prevSlide);

    sliderInterval = setTimeout(nextSlide, SLIDE_DURATION);
  }

  function getMainBannerList() {
    let mainBanner = [];
    $.ajax('/home/banners', {
      method: 'get',
      dataType: 'json',
      async: false,
      success: function(r) {
        if (typeof r !== 'undefined') {
          mainBanner = r;
        }
      },
    });

    return mainBanner;
  }

  /**
   * 슬라이드 넘김
   */
  function nextSlide() {
    let onElem = $('#mainSlide > li.on');
    let listElems = $('#mainSlide > li');

    if (onElem.index() === listElems.length - 1) {
      activeSlide(listElems.first());
    } else {
      activeSlide(onElem.next());
    }
  }

  /**
   * 슬라이드 이전으로 넘김
   */
  function prevSlide() {
    let onElem = $('#mainSlide > li.on');
    let listElems = $('#mainSlide > li');

    if (onElem.index() === 0) {
      activeSlide(listElems.last());
    } else {
      activeSlide(onElem.prev());
    }
  }

  /**
   * 지정된 slide active.
   * @param {element} el
   */
  function activeSlide(el) {
    if (isSlideProc) return;
    isSlideProc = true;

    clearTimeout(sliderInterval);

    $('#mainSlide > li.on').removeClass('on');
    el.addClass('on');

    $('#slideCurCount').text(el.index() + 1);

    setTimeout(() => {
      isSlideProc = false;
    }, FADE_DURATION);
    sliderInterval = setTimeout(nextSlide, SLIDE_DURATION);
  }

  /**
   * 지정된 데이터로 slide 추가.
   * @param {Array} list
   */
  function setSlideList(list) {
    let mainSlider = $('#mainSlide');

    list.forEach(data => {
      let html = getSlideMarkup(data);
      mainSlider.append(html);
    });

    activeSlide($('#mainSlide li').first());
  }

  /**
   * 단일 슬라이드 마크업반환
   * @param {Object} data
   */
  function getSlideMarkup(data) {
    let target = '';
    let html = '';

    if (typeof data.url === 'string' && data.url.trim().length > 0) {
      if (typeof data.target === 'string' && data.target.trim().length > 0) {
        target = ` target="${data.target}"`;
      }
      html = `
        <a href="${data.url}"${target}><div class="thumb visible-lg visible-md desktop" style='background-image: url(${data.image.url})'></div></a>
        <a href="${data.url}"${target}><div class="thumb visible-sm tablet" style='background-image: url(${data.image_tablet.url})'></div></a>
        <a href="${data.url}"${target}><div class="thumb visible-xs mobile" style='background-image: url(${data.image_mobile.url})'></div></a>
        <a href="${data.url}"${target}><div class="thumb visible-xs mobile-ratina" style='background-image: url(${data.image_retina.url})'></div></a>
      `;
    } else {
      html = `
        <div class="thumb visible-lg visible-md desktop" style='background-image: url(${data.image.url})'></div>
        <div class="thumb visible-sm tablet" style='background-image: url(${data.image_tablet.url})'></div>
        <div class="thumb visible-xs mobile" style='background-image: url(${data.image_mobile.url})'></div>
        <div class="thumb visible-xs mobile-ratina" style='background-image: url(${data.image_retina.url})'></div>
      `;
    }

    return `<li role="tab">${html}</li>`;
  }

  initSlider(BANNER_LIST);
});
